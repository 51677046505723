import tw, { css } from "twin.macro"
import styled from "@emotion/styled/macro"

import { GlobalStyles, GlobalStylesTypes } from "./Global"

const styles = {
  default: {
    headings: css`
      ${tw`font-sans font-bold`}

      a {
        ${tw``}
      }
      em {
        ${tw`italic`}
      }
      strong {
        ${tw`font-bold`}
      }
    `,
    body: css`
      ${tw`font-sans font-medium`}

      a {
        ${tw``}
      }
      em {
        ${tw`italic`}
      }
      strong {
        ${tw`font-bold`}
      }
    `,
  },
  uppercase: tw`uppercase`,
}

export const H1 = styled.h1<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-5.5xl md:text-8xl leading-tight md:leading-tighter`}
`
export const H2 = styled.h2<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-5xl md:text-5.75xl leading-none md:leading-tighter`}
`
export const H3 = styled.h3<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-4xl md:text-5xl leading-tight md:leading-tighter`}
`
export const H4 = styled.h4<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-3xl md:text-4.25xl leading-tight md:leading-tighter`}
`
export const H5 = styled.h5<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-xl md:text-2xl leading-tight md:leading-snug`}
`
export const H6 = styled.h6<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && tw`uppercase md:normal-case tracking-wider md:tracking-normal leading-loosest md:leading-tight`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-base md:text-xl leading-tight`}
`
export const P = styled.p<ContentProps>`
  ${styles.default.body}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ large }) => (large ? tw`text-base md:text-xl` : tw`text-base`)}
  ${tw`leading-normal`}
`
export const Small = styled.p<ContentProps>`
  ${styles.default.body}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ uppercase }) => uppercase && styles.uppercase}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ large }) => (large ? tw`text-sm` : tw`text-xs`)}
  ${tw`leading-tighter`}
`
export const Overline = styled.p<ContentProps>`
  ${styles.default.body}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ large }) =>
    large ? tw`text-sm leading-tight md:text-lg md:leading-relaxed font-bold tracking-widest` : tw`text-xxs leading-tight tracking-widest`}
  ${tw`uppercase`}
`
export const Notification = styled.p<ContentProps>`
  ${styles.default.body}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ colour }) => colour && GlobalStyles.background[`${colour}-pastel`]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ large }) => (large ? tw`px-5 py-2.5` : tw`px-3 py-1.5`)}
  ${tw`leading-none text-base`}
`
export const RichText = styled.div<ContentProps>`
  ${styles.default.body}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ large }) => (large ? tw`text-base md:text-xl md:leading-normal` : tw`text-base`)}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-base`}

  > div > * {
    ${css`
      &::after {
        content: "\u00A0";
        ${tw`block`};
      }
      &:last-child::after {
        ${tw`hidden`}
      }
    `}
  }

  a {
    ${tw`text-purple-dark hover:underline`}
  }

  ol,
  ul {
    ${tw`list-none mb-6`}
  }
  ol {
    ${tw`ml-4`}
    counter-reset: my-custom-counter;
    counter-increment: my-custom-counter;

    li {
      ${tw`pl-4.5`}
      &::before {
        counter-increment: my-custom-counter;
        content: counter(my-custom-counter);
        ${tw`sr-only`}
      }
      &::marker {
        content: counter(my-custom-counter) ". ";
      }
    }
  }
  ul {
    &.yotpo-share-buttons-container {
      ${tw`sm:ml-2 flex items-start gap-4 sm:flex-row`}
    }

    &:not(.yotpo-share-buttons-container) {
      ${tw`ml-2`}

      li {
        ${tw`pl-6.5`}

        &::marker {
          content: "•";
        }
      }
    }
  }
  li {
    ${tw`mb-3 last:mb-0`}
  }
`
export const Blockquote = styled.blockquote<GenericTextProps>`
  ${styles.default.headings}
  ${({ align }) => GlobalStyles.align[align]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`text-base`}
`

interface GenericTextProps {
  align?: GlobalStylesTypes["align"]
  colour?: GlobalStylesTypes["colour"]
  uppercase?: boolean
  withSpacing?: GlobalStylesTypes["spacing"]
}

interface ContentProps extends GenericTextProps {
  large?: boolean
}
